
import Decoration1SVG from '@/components/svg/Decoration1SVG.vue'
export default {
  components: { Decoration1SVG },
  props: {
    section: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tl: null,
    }
  },
  beforeMount() {
    this.$nuxt.$on('go', this.anim)
  },
  mounted() {
    this.tl = this.$gsap.timeline({
      scrollTrigger: {
        trigger: this.$el,
        toggleActions: 'play reset play reverse',
        start: 'top center',
      },
    })
    this.tl.scrollTrigger.disable()

    const svg = this.$gsap.utils.selector(this.$refs.SVG.$el)

    this.tl.from(svg('.faders')[0].children, { opacity: 0, duration: 0.3, stagger: 0.01 }, 0)
    this.tl.to(svg('.detail1'), { duration: 4, rotate: `-=60`, svgOrigin: '685.9 514.4', ease: 'sine.inOut', yoyo: true, repeat: -1, repeatDelay: 2 }, 0)
    this.tl.to(svg('.detail3'), { duration: 6, rotate: `+=45`, svgOrigin: '685.9 514.4', ease: 'sine.inOut', yoyo: true, repeat: -1, repeatDelay: 1 }, 0)
    this.tl.to(svg('.flash'), { duration: 1, opacity: 0, repeat: -1, repeatRefresh: true, ease: 'expo.out' }, 0)
    this.tl.to(svg('.cog1'), { duration: 1, rotate: `+=15`, transformOrigin: 'center', repeat: -1, repeatRefresh: true, ease: 'expo.out' }, 0)
    this.tl.to(svg('.cog2'), { duration: 2, rotate: `+=5`, transformOrigin: 'center', repeat: -1, repeatRefresh: true, ease: 'back.out(1.7)' }, '<')
    this.tl.to(svg('.cog3'), { rotate: `-=${360 / 25}`, transformOrigin: 'center', repeat: -1, repeatRefresh: true }, '<')

    this.$emit('sectionMounted')
  },
  beforeDestroy() {
    this.$nuxt.$off('go', this.anim)
  },
  methods: {
    anim() {
      this.tl.scrollTrigger.enable()
    },
  },
}
